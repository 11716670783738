<template>
  <div>

    <app-step step="1" />

    <div class="container pt-4">
      <div class="row px-3">
        <div class="col-sm-3 border border-bottom-0">
          <div class="row text-primary d-flex justify-content-center">
            <div class="col-2 my-auto py-2"><h4 class="my-0"><i class="fad fa-hand-holding-box"></i></h4></div>
            <div class="col-8 my-auto py-2"><h5 class="my-0">Entrega rápida</h5></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">

      <hr class="mt-0">
      
      <div class="row d-flex justify-content-between">
        <h5 class="px-3 my-0 text-primary mb-3">Instrucciones de entrega rápida en sucursales</h5>
        <b-form class="container">
          
          <p class="text-muted">En X-orsystem estámos al tanto de la seguridad y la salud de nuestros clientes.</p>
          <p class="text-muted">Debido a la actual crisis sanitaria hemos implementado un protocolo de entrega rápida y segura, respetando cada uno de los lineamientos establecidos por las autoridades.</p>
          <p class="text-muted"></p>
          <p class="text-muted">Para disminuir el riesgo de contagios te recomendamos:</p>

          <div class="row py-4 px-3 mt-2 mb-4">
            <b-button variant="white" class="border col-sm-3 py-3">
              <span class="display-3 text-primary"><i class="fad fa-handshake-slash"></i></span>
              <p class="small text-muted pt-2 mb-0">Evita el saludos con contacto físico.</p>
            </b-button>
            <b-button variant="white" class="border col-sm-3 py-3">
              <span class="display-3 text-primary"><i class="fad fa-people-arrows"></i></span>
              <p class="small text-muted pt-2 mb-0">Mantén una sana distancia.</p>
            </b-button>
            <b-button variant="white" class="border col-sm-3 py-3">
              <span class="display-3 text-primary"><i class="fad fa-head-side-mask"></i></span>
              <p class="small text-muted pt-2 mb-0">Usa cubrebocas.</p>
            </b-button>
            <b-button variant="white" class="border col-sm-3 py-3">
              <span class="display-3 text-primary"><i class="fad fa-pump-soap"></i></span>
              <p class="small text-muted pt-2 mb-0">Usa gel antibacterial.</p>
            </b-button>
          </div>

          <p class="text-muted"><strong>Es importante que al término de la orden se comunique mediante Whatsapp al número <a href="https://api.whatsapp.com/send?phone=523314796135" target="_blank">(33) 1479 6135</a>.</strong></p>

          <hr>
          <p class="text-muted">Favor de seleccionar y llenar los datos para agilizar el proceso:</p>
          <div class="row d-flex justify-content-between px-3 mb-2">
            <div class="col-sm-3 px-0">
              <p class="mb-1 text-muted">Horario</p>
              <b-form-select 
                v-model="hour" 
                class="mb-2"
                :options="hours"
              ></b-form-select>
            </div>
            <div class="col-sm-8 px-0">
              <p class="mb-1 text-muted">Ubicación</p>
              <b-form-select 
                v-model="location" 
                class="mb-2"
                :options="locations"
              ></b-form-select>
            </div>
          </div>

          <p class="text-muted">Si tiene algún comentario favor de ingresarlo a continuación:<p>

          <div class="row px-3">
            <b-form-textarea
              v-model="comentaries"
              placeholder="Comentarios"
              rows="2"
              max-rows="4"
            ></b-form-textarea>
          </div>

        </b-form>
      </div>

      <div class="row d-flex justify-content-right mt-5">
        <div class="col-sm-3 ml-auto">
          <b-button v-on:click="go_to(1)" variant="white" block>Requiero Factura</b-button>
        </div>
        <div class="col-sm-4">
          <b-button v-on:click="go_to(2)" variant="primary" block>Siguiente</b-button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Shipping',
  data() {
    return {
      customer: '',
      hour: '',
      location: '',
      comentaries: ''
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  methods: {
    go_to(id) {
      var shipping = this.$store.getters.get_shipping
      
      shipping.data = { 
        firstname: this.customer.firstname,
        lastname: this.customer.lastname,
        company: this.customer.firstname,
        address: this.location.address,
        city: this.location.city,
        postcode: this.location.postcode,
        country: this.location.country,
        country_id: this.location.country_id,
        zone: this.location.zone,
        zone_id: this.location.zone_id,
        comment: this.comentaries,
      }
      this.$store.commit('set_shipping', shipping)

      switch (id) {
        case 1: this.$router.push('/invoice'); break;
        case 2: this.$router.push('/payment'); break;
      }
    }
  },
  created() {
    this.customer = this.$store.getters.get_customer
    this.hour = '12'
    this.comentaries = ''
    this.location = { 
          id: 1, 
          address: 'Plaza de la Tecnología, Av. 16 de Septiembre 140', 
          city: 'Guadalajara Centro', 
          postcode: '44100', 
          country: 'Mexico', 
          country_id: '138', 
          zone: 'Jalisco', 
          zone_id: '2158' 
        }

    this.hours = [
      { value: '12', text: '12:00 p.m.'},
      { value: '15', text: '03:00 p.m.'},
      { value: '18', text: '06:00 p.m.'},
    ]

    this.locations = [
      { 
        value: { 
          id: 1, 
          address: 'Plaza de la Tecnología, Av. 16 de Septiembre 140', 
          city: 'Guadalajara Centro', 
          postcode: '44100', 
          country: 'Mexico', 
          country_id: '138', 
          zone: 'Jalisco', 
          zone_id: '2158' 
        }, 
        text: 'Plaza de la Tecnología, Av. 16 de Septiembre 140, Centro, 44100 Guadalajara, Jal.' 
      },
      /*{ 
        value: { 
          id: 2, 
          address: 'Genaro Padilla 196A', 
          city: 'El Pitillal, Puerto Vallarta', 
          postcode: '48290', 
          country: 'Mexico', 
          country_id: '138', 
          zone: 'Jalisco', 
          zone_id: '2158' 
        }, 
        text: 'Puerto Vallarta, Genaro Padilla 196A El Pitillal, 48290 Puerto Vallarta, Jal.' 
      },*/
    ]
  },
}
</script>